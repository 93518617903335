import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { FaTrash } from "react-icons/fa";
import useStore from "../store";


function Cart({ setCartOpen }) {
  const [total, setTotal] = useState(0);
  const cart = useStore((state) => state.cart);
  const [price_ids, setPrice_ids] = useState([]);
  const [sending, setSending] = useState(false);


  useEffect(() => {
    let total = 0;
    cart.forEach((producto) => {
      total += producto.precio;
    });
    setTotal(total);
  }, [cart]);

  useEffect(() => {
    const price_ids = cart.map((producto) => producto.price_id);
    setPrice_ids(price_ids);
  }, [cart]);

  const handleCheckout = async () => {
    setSending(true);
    const response = await fetch("https://kumotiendaback.vercel.app/checkout", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(price_ids),
    });
    const { checkoutUrl } = await response.json();
    window.location.href = checkoutUrl;
  };


  return (
    <div className="fixed top-0 w-full h-screen flex flex-col items-center justify-start bg-black/80">
      <motion.div
        animate={{ x: 0 }}
        initial={{ x: 1000 }}
        transition={{ duration: 0.5 }}

        className="w-[90vw] bg-white border border-white rounded-2xl py-10 px-10 flex flex-col gap-6 mt-6 mb-32 overflow-auto">
        {cart.map((producto) => (
          <div className="flex flex-col bg-black justify-center items-center gap-4 rounded-2xl py-6">
            <img
              src={producto.imagen}
              className="w-14 h-14 rounded-full object-cover border border-black bg-white"
              alt={producto.nombre}
            />
            <p className="text-white">{producto.nombre}</p>
            {producto.talla && (
              <p className="text-white">{producto.talla} cms</p>
            )}
            {producto.color && (
              <p className="text-white">Color {producto.color}</p>
            )}
            <p className="text-white">${producto.precio}</p>
            <FaTrash
              size={20}
              className="text-white text-2xl cursor-pointer"
              onClick={() => {
                useStore.getState().removeFromCart(producto);
              }}
            />
          </div>
        ))}

        <p className=" w-full text-center">Total: ${total.toLocaleString()}</p>
        <button
          className="bg-red-600 text-white rounded-xl py-2 px-4"
          onClick={handleCheckout}
        >
          {sending ? "Procesando..." : "Pagar"}
        </button>

        <button
          className="bg-gray-700 text-white rounded-xl py-2 px-4"
          onClick={() => {
            setCartOpen(false);
          }}
        >
          Seguir comprando
        </button>
      </motion.div>
    </div>
  );
}

export default Cart;
