import React, { useEffect } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import useStore from "../store";
import { supabase } from "../supabase";

function ThankYou() {
  const navigate = useNavigate();
  const params = useParams();
  const cart = useStore((state) => state.cart);
  const setCart = useStore((state) => state.setCart);
  const user = useStore((state) => state.user);

  useEffect(() => {
    if (params.checkout && cart.length > 0) {
      const pedido = cart.map((producto) => ({
        nombre: producto.nombre,
        precio: producto.precio,
        talla: producto.talla ? producto.talla : "",
        color: producto.color ? producto.color : "",
      }));

      console.log(pedido);

      supabase
        .from("Pedidos")
        .insert(pedido)
        .then(({ data, error }) => {
          if (error) console.log(error);
          else {
            const msg = `NUEVO PEDIDO: ${cart
              .map(
                (producto) =>
                  `\n${producto.nombre} talla ${
                    producto.talla ? producto.talla : ""
                  } color ${producto.color ? producto.color : ""}`
              )
              .join(", ")}`;
            const url = `https://kumotiendaback.vercel.app/telegram_msg?msg=${msg}`;
            fetch(url).then((res) => {
              console.log(res);
            });
          }
          setCart([]);
        });
    }
  }, [params.checkout, cart, setCart, supabase]);

  return (
    <div>
      <div
        className="w-full h-screen bg-contain bg-bottom bg-no-repeat bg-black text-white flex flex-col justify-start items-center pt-20 text-3xl font-bold px-20"
        style={{
          backgroundImage: `url("https://res.cloudinary.com/dt9ivv2ug/image/upload/v1694808532/thankyou_1_ixvp7x.png")`,
        }}
      >
        <h1 className="text-5xl font-bold text-center text-white">
          ¡¡Gracias por tu compra!!
        </h1>
        <div className="absolute top-10 right-10 h-6 w-6 rounded-full flex justify-center items-center">
          <AiFillCloseCircle size={25} onClick={() => navigate("/")} />
        </div>
      </div>
    </div>
  );
}

export default ThankYou;
